import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataNurseNotesState = atom({
  key: 'dataNurseNotesState',
  default: { rows: [], meta: {} },
})

export const fetchNurseNotes = async (medicalId, params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`nursenotes/records/${medicalId}?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createNurseNotes = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('nursenotes/newrecord', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
